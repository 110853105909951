<template>
  <v-menu left transition="scale-transition" offset-y bottom rounded>
    <template v-slot:activator="{ on, attrs }">
      <a
        v-bind="attrs"
        v-on="on"
        class="btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"
      >
        Actions
        <span class="svg-icon svg-icon-5 m-0">
          <v-icon small>mdi-chevron-down</v-icon>
        </span>
      </a>
    </template>
    <v-card class="poppins py-1" elevation="0">
      <template v-for="action in rowActions">
        <v-btn
          :key="action.name"
          v-if="action.isVisible"
          class="d-block w-100"
          text
          @click="() => handle_function_call(action.method, item)"
          >{{ action.title }}</v-btn
        >
      </template>
    </v-card>
  </v-menu>
</template>
<script>
/**
 * universal action button creator component for datatables
 * @var item
 */

export default {
  name: "DataTableActionSelectorNew",
  props: {
    item: { required: true },
    actions: { required: true },
    handle_function_call: { required: true },
  },
  computed: {
    rowActions: function () {
      return [
        {
          name: "approve",
          title: this.$t("approve"),
          type: "normal",
          method: "approveAction",
          isVisible: this.item?.extra_data?.detail?.actions?.approve,
        },
        {
          name: "cancel",
          title: this.$t("cancel"),
          type: "normal",
          method: "cancelAction",
          isVisible: true,
        },
        {
          name: "complete",
          title: this.$t("complete"),
          type: "normal",
          method: "completeAction",
          isVisible: true,
        },
        {
          name: "delete",
          title: this.$t("remove"),
          type: "normal",
          method: "removeItem",
          isVisible: true,
        },
        {
          name: "update",
          title: this.$t("edit"),
          type: "normal",
          method: "editItem",
          isVisible: true,
        },
      ];
    },
  },
};
</script>
